import { render, staticRenderFns } from "./ResumeAdd.vue?vue&type=template&id=5fdf62f9&scoped=true"
import script from "./ResumeAdd.vue?vue&type=script&lang=js"
export * from "./ResumeAdd.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fdf62f9",
  null
  
)

export default component.exports