<template>
  <section>
    <div class="headerjob">
      <v-container class="container-custom">
        <v-row>
          <v-col> <h1 class="titlepage">Add Your Details</h1></v-col>
        </v-row>
      </v-container>
    </div>
    <v-container class="container-custom padding-inside">
      <v-row>
        <v-col md="12">
          <AddResume />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import AddResume from "@/components/AddResume";
export default {
  name: "ResumeAdd",
  components: { AddResume },
  data: () => ({
    headercontent: {
      title: "Add Resume",
      addlink: "/dashboard/training/add",
      downloadlink: true,
    },
  }),
};
</script>

<style lang="scss" scoped></style>
